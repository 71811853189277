import { str } from 'envsafe';
import { envsafeExtended } from '../envsafe-extended';

export const { NEXT_PUBLIC_BUILDER_API_KEY, NEXT_PUBLIC_REVALIDATION } = envsafeExtended({
  NEXT_PUBLIC_BUILDER_API_KEY: str({
    input: process.env.NEXT_PUBLIC_BUILDER_API_KEY,
  }),
  NEXT_PUBLIC_REVALIDATION: str({
    allowEmpty: true,
    default: '60',
  }),
});
