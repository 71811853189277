'use client';
import * as React from 'react';
import builder, { BuilderComponent } from '@builder.io/react';
import { BuilderComponentProps } from '@builder.io/react/dist/types/src/components/builder-component.component';
import { NEXT_PUBLIC_BUILDER_API_KEY } from '@dreamplan/envs/public/builderio';
import { PageHeader } from '../../../Layout/AppHeader';
import { registerNewBuilderInserts } from '../../../NewWebsite/builderInserts';
import { registerBuilderInserts } from '../../util/builder/builderInserts';

registerBuilderInserts();
registerNewBuilderInserts();

builder.init(NEXT_PUBLIC_BUILDER_API_KEY, false);
const BuilderPage = ({
  model,
  page,
}: {
  model: string;
  page: BuilderComponentProps;
  locale: string;
}) => {
  const { title, description, image } = page?.data || {};

  return (
    <div>
      {title && <PageHeader title={title} description={description} image={image} />}

      <BuilderComponent model={model} content={page} data={page.data} />
    </div>
  );
};

export default BuilderPage;
